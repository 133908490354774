<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_estado_pendiente">
                            <mensaje-predefinido-datos-crear @crear="crear()"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import MensajePredefinidoDatosCrear from './MensajePredefinidoDatosCrear.vue'
export default {
    components: {
        'mensaje-predefinido-datos-crear': MensajePredefinidoDatosCrear,
    },
    props: [],
    data() {
        return {
            mensaje_predifinido: [],
        }
    },
    methods: {
        crear(){
            this.$emit('crear');
        }
    },
    mounted() {
    },
    watch: {
    }
}
</script>
